// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminDashboard = () => {
    const [file, setFile] = useState(null);
    const [images, setImages] = useState([]);
    const [error, setError] = useState('');
    
    // Fetch images on component mount
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('http://localhost:8630/api/images');
                setImages(response.data.images);
            } catch (err) {
                setError('Fehler beim Laden der Bilder');
            }
        };

        fetchImages();
    }, []);
    
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            setError('Bitte wählen Sie eine Datei aus');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post('http://localhost:8630/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setImages([...images, response.data.imageUrl]);
            setFile(null);
            setError('');
        } catch (err) {
            setError('Fehler beim Hochladen des Bildes');
        }
    };

    const handleDelete = async (imageUrl) => {
        try {
            const filename = imageUrl.split('/').pop(); // Extract filename
            await axios.delete(`http://localhost:8630/api/images/${encodeURIComponent(filename)}`);
            setImages(images.filter(img => img !== imageUrl));
        } catch (err) {
            setError('Fehler beim Entfernen des Bildes');
        }
    };

    return (
        <div>
            <h2>Admin Dashboard</h2>
            <div>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleUpload}>Hochladen</button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
            <div>
                {images.length > 0 ? (
                    <div>
                        {images.map((image, index) => (
                            <div key={index}>
                                <img src={`http://localhost:8630${image}`} alt={`Image ${index}`} style={{ width: '200px', height: 'auto' }} />
                                <button onClick={() => handleDelete(image)}>Entfernen</button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Keine Bilder vorhanden</p>
                )}
            </div>
        </div>
    );
};

export default AdminDashboard;
