// src/components/Modal.js
import React from 'react';
import '../assets/css/Modal.css'; // Optional: Styling für das Modal

const Modal = ({ show, onClose, content }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>✕</button>
                <div className="modal-body">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Modal;
