import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminDashboard from './pages/AdminDashboard';
import AdminLogin from './pages/AdminLogin';
import Frontend from './pages/Frontend';

function App() {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Frontend />} />
                <Route path="/admin-login" element={<AdminLogin />} />
                <Route path="/admin-dashboard" element={<AdminDashboard />} />
            </Routes>
        </Router>
    );
}

export default App;
