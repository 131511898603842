import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, Element, scroller } from 'react-scroll';
import Modal from '../components/Modal';
import '../assets/css/Frontend.css';

const Frontend = () => {
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        mail: '',
        phone: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // Fetch images from the server
        axios.get('http://localhost:8630/api/images')
            .then(response => {
                setImages(response.data.images);
            })
            .catch(error => {
                console.error('Error fetching images:', error);
            });

        const handleScroll = () => {
            const header = document.querySelector('header');
            if (window.scrollY > 50) {
                header.classList.add('scrolled');
            } else {
                header.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post('/send_email.php', formData) // <-- Pfad zur PHP-Datei
            .then(response => {
                setLoading(false);
                setSuccessMessage('Ihre Nachricht wurde erfolgreich gesendet.');
                setFormData({
                    name: '',
                    mail: '',
                    phone: '',
                    message: ''
                });
            })
            .catch(error => {
                setLoading(false);
                setError('Fehler beim Senden der Nachricht.');
            });
    };

    // Split images into large and small sets
    const largeImage = images[0];
    const smallImages = images.slice(1);

    // Check if there are images to display
    const hasImages = images.length > 0;

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
    };

    const impressumContent = (
        <div>
            <h2>Impressum</h2>
            <p><strong>Angaben gemäß § 5 TMG:</strong></p>
            <p>Barbara Wutz<br />
                Buttermarkt 18A<br />
                47906 Kempen</p>

            <p><strong>Kontakt:</strong></p>
            <p>Telefon: +49 (0)163 4131154<br />
                E-Mail: info@lafe-atelier.de<br />
                Website: lafe-atelier.de</p>

            <p><strong>Vertretungsberechtigte Person:</strong></p>
            <p>Barbara Wutz (Geschäftsführerin)</p>

            <p><strong>Haftungsausschluss:</strong></p>
            <p>Die Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann jedoch keine Gewähr übernommen werden.</p>

            <p><strong>Haftung für Links:</strong></p>
            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte keine Gewähr übernehmen.</p>

            <p><strong>Urheberrecht:</strong></p>
            <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet.</p>

            <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
            <p>Barbara Wutz<br />
                Buttermarkt 18A<br />
                47906 Kempen</p>
        </div>
    );


    const datenschutzContent = (
        <div>
            <h2>Datenschutz</h2>
            <p><strong>Erhebung und Nutzung von Daten</strong></p>
            <p>Wenn Sie unser Kontaktformular verwenden, werden die von Ihnen eingegebenen Daten an die Betreiberin von Atelier Lafe, Barbara Wutz, übermittelt. Diese Daten werden ausschließlich zur Bearbeitung Ihrer Anfrage und zur Kontaktaufnahme mit Ihnen verwendet.</p>

            <p>Ihre Daten werden nicht an Dritte weitergegeben und nur für den Zweck verwendet, für den Sie sie uns zur Verfügung gestellt haben.</p>

            <p><strong>Speicherung und Sicherheit</strong></p>
            <p>Wir sorgen dafür, dass Ihre Daten sicher und gemäß den geltenden Datenschutzbestimmungen gespeichert werden.</p>

            <p><strong>Kontakt</strong></p>
            <p>Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer Daten können Sie uns unter der folgenden E-Mail-Adresse kontaktieren: info@lafe-atelier.de.</p>
        </div>
    );

    return (
        <div>
            <header className="header">
                <div className="nav-main">
                    <img src="assets/img/brand-logo.svg" alt="Brand Logo" className="brand-logo-header" />
                    <ul>
                        <li><Link to="brand" smooth={true} duration={500}>Home</Link></li>
                        {hasImages ? (<li><Link to="works" smooth={true} duration={500}>Meine Werke</Link></li>) : ''}
                        <li><Link to="contact" smooth={true} duration={500}>Kontakt</Link></li>
                    </ul>
                </div>
            </header>
            <main>
                <div className="brand">
                    <img src="assets/img/brand-logo.svg" alt="Brand Logo" />
                    <img className="scroll-icon" src="assets/img/mouse-scroll.svg" alt="Scroll Icon" />
                </div>
                <div className="home">
                    <div className="home-text">
                        <h2>LaFe <span class="bellarina">Atelier</span> - <span>Sei du selbst</span></h2>
                        <p>Ich lade Dich in meine zauberhafte Welt ein.</p>
                        <p>LaFe ist ein kleines, feines Atelier für Latexmode.</p>
                        <p>Hier ist der Ort für Kreativität und Einzigartigkeit.</p>
                        <p>Du wünschst Dir etwas Besonderes?<br />Du kannst es nirgendwo finden?</p>
                        <p>Als Designerin setze ich Deine Wünsche gekonnt um und fertige Mode nach Maß an.</p>
                        <p>Deine Individualität steht im Mittelpunkt.</p>
                    </div>
                    <div className="home-image">
                        <img src="assets/img/profile.svg" alt="Profile" />
                    </div>
                </div>
                <div className="quote">
                    <div className="quote-text">"Fetische sind etwas Wunderbares. Sie zaubern Regenbögen in unser Leben."</div>
                    <div className="quote-author">UNBEKANNT</div>
                    <div className="quote-job"></div>
                </div>
                {hasImages ? (
                    <div className="works">
                        <h2>Meine Werke</h2>

                        <div className="boxes">
                            <div className="big-box">
                                <img src={`http://localhost:8630${largeImage}`} alt="Werk 1" />
                            </div>
                            <div className="small-box">
                                <div className="small">
                                    {smallImages.slice(0, 3).map((image, index) => (
                                        <div key={index}>
                                            <img src={`http://localhost:8630${image}`} alt={`Werk ${index + 2}`} />
                                        </div>
                                    ))}
                                </div>
                                <div className="small">
                                    {smallImages.slice(3, 6).map((image, index) => (
                                        <div key={index + 3}>
                                            <img src={`http://localhost:8630${image}`} alt={`Werk ${index + 5}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''}
                <div className="contact">
                    <h2>Kontakt</h2>
                    <div className="table main-table">
                        <div className="row text-row">
                            <p>Wenn Sie mehr erfahren möchten können<br />
                                Sie mich anrufen oder per Formular<br />
                                anschreiben</p>
                            <p>Barbara Wutz</p>
                            <p>Tel: +49(0)1634131154</p>
                            <p>E-Mail info@lafe-atelier.de</p>
                        </div>
                        <div className="row">
                            <div className="table second-table">
                                <form onSubmit={handleSubmit}>
                                    <div className="input-area">
                                        <label htmlFor="name">Name eingeben*</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-area">
                                        <label htmlFor="mail">E-Mail Adresse eingeben*</label>
                                        <input
                                            type="email"
                                            name="mail"
                                            id="mail"
                                            value={formData.mail}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-area">
                                        <label htmlFor="phone">Telefonnummer eingeben</label>
                                        <input
                                            type="tel"
                                            name="phone"
                                            id="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-area">
                                        <label htmlFor="message">Nachricht eingeben*</label>
                                        <textarea
                                            name="message"
                                            id="message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            required
                                        ></textarea>
                                    </div>
                                    <button type="submit" disabled={loading}>
                                        {loading ? 'Senden...' : 'Absenden'}
                                    </button>
                                </form>
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote">
                    <div className="quote-text">"Eleganz heißt nicht, ins Auge zu fallen, sondern im Gedächtnis zu bleiben."</div>
                    <div className="quote-author">GIORGIO ARMANI</div>
                    <div className="quote-job">Modedesigner</div>
                </div>
            </main>

            {/* Modal für Impressum und Datenschutz */}
            <Modal show={isModalOpen} onClose={closeModal} content={modalContent} />

            <footer>
                <div className="table">
                    <div className="brand-footer">
                        <img src="assets/img/footer-brand-logo.svg" alt="Footer Brand Logo" />
                    </div>
                    <div className="nav-footer">
                        <ul>
                            <li><a onClick={() => openModal(impressumContent)}>Impressum</a></li>
                            <li><a onClick={() => openModal(datenschutzContent)}>Datenschutz</a></li>
                        </ul>
                    </div>
                    <div className="social-media">
                        {/* Social Media Links hier einfügen */}
                    </div>
                </div>
                <p>Copyright 2024 &copy; Barbara Wutz</p>
            </footer>
        </div>
    );
};

export default Frontend;
