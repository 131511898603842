import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Login-Handler
    const handleLogin = (e) => {
        e.preventDefault();

        const adminUsername = 'adminBarbara';
        const adminPassword = 'password123';

        if (username === adminUsername && password === adminPassword) {
            localStorage.setItem('isAuthenticated', 'true');
            navigate('/admin-dashboard');
        } else {
            setError('Ungültiger Benutzername oder Passwort');
        }
    };

    // Überprüfen, ob der Benutzer bereits authentifiziert ist
    useEffect(() => {
        if (localStorage.getItem('isAuthenticated') === 'true') {
            navigate('/admin-dashboard');
        }
    }, [navigate]);

    return (
        <div>
            <h2>Admin Login</h2>
            <form onSubmit={handleLogin}>
                <div>
                    <label htmlFor="username">Benutzername:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">Passwort:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default AdminLogin;
